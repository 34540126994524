import React from 'react'
import {BrowserRouter as Router, Route, Link, Switch, Routes} from 'react-router-dom';

import Header from './components/Header'
import Aside from './components/Aside'
import Content from './components/Content'
import RegistroServicios from './Pages/Servicios/RegistroServicios'
import ViewPDF from './Pages/Servicios/ViewPDF'
import Footer from './components/Footer'

export default function App() {
  return (
   
      <div>
        <Header/>
        <Aside />
          <Router>
            <Routes>
              <Route  path="/" element={<RegistroServicios />}></Route>
              <Route  path="/pdf" element={<ViewPDF />}></Route>
            </Routes>
          </Router>
        {/* <Content /> */}
        <Footer />
      </div>
   
  )
}
