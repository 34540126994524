
import React from "react";
import { PDFViewer } from '@react-pdf/renderer';
import PDFFile from "../../components/PDFFile";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import logo from '../../img/mip.png'
import firmas from '../../img/firma_samuel.png'
import firmaj from '../../img/firma_julio.png'

export default function RegistroServicios() {

    
  const facturaData = 
    {
    numero: 'Interior Bodega 3',
    producto: 'Nula',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'ASPERSIÓN LINEAL',
    fecha: 'LT',
    cliente: 'SI',
    total: 'SI',
    };

    const facturaData2 = 
    {
    numero: 'Interior Bodega 4',
    producto: 'BAJA',
    cantidad: 'INSECTOS VOLADORES',
    precio: 'ASPERSIÓN EN BANDEO',
    fecha: 'LT',
    cliente: 'SI',
    total: 'SI',
    };

    const facturaData3 = 
    {
    numero: 'Interior Bodega 5',
    producto: 'ALTA',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'ASPERSIÓN LINEAL',
    fecha: 'LT',
    cliente: 'NO',
    total: 'NO',
    };

    const productosData = 
    {
    numero: 'BROMADIOLONA',
    producto: 'C-REAL B PARAFINADO',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'RSCO-URB-RODE-511-339-033-0.005',
    fecha: '.5',
    cliente: '.5',
    };

    const productosData2 = 
    {
    numero: 'Thiametoxam',
    producto: 'Optigard Flex',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'RSCO-URB-INAC-102U-315-064-21',
    fecha: '.5',
    cliente: '.5',
    };

  const generarPDF = () => {
    const doc = new jsPDF();

    //Encabezado de la factura
    // doc.text('Factura', 95, 20);
    doc.setFontSize(10);
    doc.addImage(`${logo}`, 'JPEG', 10, 7, 25, 20);

    doc.text("Registro Servicios", 55, 12);
    doc.text("LICENCIA SANITARIA: 17 22 12 AP 17", 55, 17);
    doc.text("Reporte de Servicios", 55, 22);

    doc.text("FOLIO: IB02-01", 150, 12);
    doc.text("CÓDIGO: REG-OP-01", 150, 17);
   

    doc.text("CLIENTE: ÁREAS INSPECCIONADAS Y/O TRATADAS", 12, 40);
    doc.text("DIRECCION: Av. 5 de Febrero 1323, Carrillo, 76130 Santiago de Querétaro, Qro.", 12, 45);
    doc.text("FECHA: JUEVES,  25 DE ENERO DE 2024.", 12, 50);

    doc.text("HR. ENTRADA:  ", 150, 40);
    doc.text("HR. SALIDA:", 150, 45);


   
    // doc.text(`Número de factura: ${facturaData.numero}`, 10, 20);
    // doc.text(`Fecha: ${facturaData.fecha}`, 10, 30);
    // doc.text(`Cliente: ${facturaData.cliente}`, 10, 40);
    // doc.text(`Total: ${facturaData.total}`, 10, 50);

    //Crear una tabla para los detalles
    const columns = ['Áreas Inspeccionadas y/o Tratadas', 'Actividad Presente', 'Tipo de Plaga Encontrada ', 
    'Método Aplicación ', 'Cantidad Utilizada', 'Adeacuado Saneamiento','Adecuado Mantenimiento'];

    const columnsProductos = ['Ingrediente Activo', 'Nombre Comercial', 'Registro Cofepris','Dosis', 'Mezcla Total']; 
                            
    const data = [
        [`${facturaData.numero}`,`${facturaData.producto}`,`${facturaData.cantidad}`,
         `${facturaData.precio}`,`${facturaData.fecha}`,`${facturaData.cliente}`,`${facturaData.total}`],
         [`${facturaData2.numero}`,`${facturaData2.producto}`,`${facturaData2.cantidad}`,
         `${facturaData2.precio}`,`${facturaData2.fecha}`,`${facturaData2.cliente}`,`${facturaData2.total}`],
         [`${facturaData3.numero}`,`${facturaData3.producto}`,`${facturaData3.cantidad}`,
         `${facturaData3.precio}`,`${facturaData3.fecha}`,`${facturaData3.cliente}`,`${facturaData3.total}`]
    ];

    const dataP = [
        [`${productosData2.numero}`,`${productosData2.producto}`,`${productosData2.cantidad}`,
        `${productosData2.precio}`,`${productosData2.fecha}`],
    ];


    // const data = facturaData;

    doc.autoTable({
        startY: 65,
        head: [columns],
        body: data,
        theme: 'grid',
        
    })

    doc.text("PRODUCTOS PLAGUICIDAS UTILIZADOS", 75, 155);
    doc.autoTable({
        startY: 150,
        head: [columnsProductos],
        body: dataP,
        theme: 'grid',
        
    })
  
    doc.addImage(`${firmas}`, 'JPEG', 32, 230, 25, 20);
    doc.text("ING. SAMUEL LÓPEZ HERNÁNDEZ", 12, 255);
    doc.text("Nombre y Firma", 27, 260);

    doc.addImage(`${firmaj}`, 'JPEG', 95, 230, 25, 20);
    doc.text("JOSE JULIO HERNANDEZ UGALDE", 80, 255);
    doc.text("Nombre y Firma", 95, 260);

    doc.text("Nombre y Firma", 155, 260);



    //guardar pdf con nombre en especifico 
    doc.save(`registro_servicio_90_05_2024.pdf`);

  }
  
    return (
        <div className="content-wrapper">
            <h1>FACTURA</h1>
            <h4>Aqui va el logo</h4>
            <p>Número de factura: {facturaData.numero}</p>
            <p>Fecha: {facturaData.producto}</p>
            <p>Cliente: {facturaData.cliente}</p>
            <p>Total:{facturaData.total}</p>
            <button onClick={generarPDF}>Generar PDF</button>
            {/* <h2>PDF FILE</h2>
            <PDFViewer height={800} width={800}>
                <PDFFile/>
            </PDFViewer> */}
          </div>
    )
}