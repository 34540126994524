import React from 'react'

export default function footer() {
  return (
    <div>
    {/* Main Footer */}
<footer className="main-footer">
  <strong>Copyright © 2024 - 2025
    <a href="https://adminlte.io"> Manejo Integrado de Plagas Santa Fe del Bajio</a>.</strong>
  Todos los derechos reservados.
  <div className="float-right d-none d-sm-inline-block">
    <b>Version</b> 1.0.0
  </div>
</footer>

    </div>
  )
}
