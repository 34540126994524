import React from "react";
import PDFFile from '../../components/PDFFile';
import { PDFDownloadLink } from "@react-pdf/renderer";
// import ImagePicker from "./components/ImagePicker";

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import logo from '../../img/mip.png'
import firmas from '../../img/firma_samuel.png'
import firmaj from '../../img/firma_julio.png'

export default function RegistroServicios() {

  const facturaData = 
    {
    numero: 'Interior Bodega 3',
    producto: 'Nula',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'ASPERSIÓN LINEAL',
    fecha: 'LT',
    cliente: 'SI',
    total: 'SI',
    };

    const facturaData2 = 
    {
    numero: 'Interior Bodega 4',
    producto: 'BAJA',
    cantidad: 'INSECTOS VOLADORES',
    precio: 'ASPERSIÓN EN BANDEO',
    fecha: 'LT',
    cliente: 'SI',
    total: 'SI',
    };

    const facturaData3 = 
    {
    numero: 'Interior Bodega 5',
    producto: 'ALTA',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'ASPERSIÓN LINEAL',
    fecha: 'LT',
    cliente: 'NO',
    total: 'NO',
    };

    const productosData = 
    {
    numero: 'BROMADIOLONA',
    producto: 'C-REAL B PARAFINADO',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'RSCO-URB-RODE-511-339-033-0.005',
    fecha: '.5',
    cliente: '.5',
    };



    const productosData2 = 
    {
    numero: 'Thiametoxam',
    producto: 'Optigard Flex',
    cantidad: 'INSECTOS RASTREROS',
    precio: 'RSCO-URB-INAC-102U-315-064-21',
    fecha: '.5',
    cliente: '.5',
    };

  const generarPDF = () => {
    const doc = new jsPDF();

    //Encabezado de la factura
    // doc.text('Factura', 95, 20);
    doc.setFontSize(10);
    doc.addImage(`${logo}`, 'JPEG', 10, 7, 25, 20);

    doc.text("Registro Servicios", 55, 12);
    doc.text("LICENCIA SANITARIA: 17 22 12 AP 17", 55, 17);
    doc.text("Reporte de Servicios", 55, 22);

    doc.text("FOLIO: IB02-01", 150, 12);
    doc.text("CÓDIGO: REG-OP-01", 150, 17);
   

    doc.text("CLIENTE: ÁREAS INSPECCIONADAS Y/O TRATADAS", 12, 40);
    doc.text("DIRECCION: Av. 5 de Febrero 1323, Carrillo, 76130 Santiago de Querétaro, Qro.", 12, 45);
    doc.text("FECHA: JUEVES,  25 DE ENERO DE 2024.", 12, 50);

    doc.text("HR. ENTRADA:  ", 150, 40);
    doc.text("HR. SALIDA:", 150, 45);


   
    // doc.text(`Número de factura: ${facturaData.numero}`, 10, 20);
    // doc.text(`Fecha: ${facturaData.fecha}`, 10, 30);
    // doc.text(`Cliente: ${facturaData.cliente}`, 10, 40);
    // doc.text(`Total: ${facturaData.total}`, 10, 50);

    //Crear una tabla para los detalles
    const columns = ['Áreas Inspeccionadas y/o Tratadas', 'Actividad Presente', 'Tipo de Plaga Encontrada ', 
    'Método Aplicación ', 'Cantidad Utilizada', 'Adeacuado Saneamiento','Adecuado Mantenimiento'];

    const columnsProductos = ['Ingrediente Activo', 'Nombre Comercial', 'Registro Cofepris','Dosis', 'Mezcla Total']; 
                            
    const data = [
        [`${facturaData.numero}`,`${facturaData.producto}`,`${facturaData.cantidad}`,
         `${facturaData.precio}`,`${facturaData.fecha}`,`${facturaData.cliente}`,`${facturaData.total}`],
         [`${facturaData2.numero}`,`${facturaData2.producto}`,`${facturaData2.cantidad}`,
         `${facturaData2.precio}`,`${facturaData2.fecha}`,`${facturaData2.cliente}`,`${facturaData2.total}`],
         [`${facturaData3.numero}`,`${facturaData3.producto}`,`${facturaData3.cantidad}`,
         `${facturaData3.precio}`,`${facturaData3.fecha}`,`${facturaData3.cliente}`,`${facturaData3.total}`]
    ];

    const dataP = [
      [`${productosData.numero}`,`${productosData.producto}`,`${productosData.cantidad}`,
      `${productosData.precio}`,`${productosData.fecha}`],
        [`${productosData2.numero}`,`${productosData2.producto}`,`${productosData2.cantidad}`,
        `${productosData2.precio}`,`${productosData2.fecha}`],
    ];


    // const data = facturaData;

    doc.autoTable({
        startY: 65,
        head: [columns],
        body: data,
        theme: 'grid',
        
    })

    doc.text("PRODUCTOS PLAGUICIDAS UTILIZADOS", 75, 155);
    doc.autoTable({
        startY: 150,
        head: [columnsProductos],
        body: dataP,
        theme: 'grid',
        
    })
  
    doc.addImage(`${firmas}`, 'JPEG', 32, 230, 25, 20);
    doc.text("ING. SAMUEL LÓPEZ HERNÁNDEZ", 12, 255);
    doc.text("Nombre y Firma", 27, 260);

    doc.addImage(`${firmaj}`, 'JPEG', 95, 230, 25, 20);
    doc.text("JOSE JULIO HERNANDEZ UGALDE", 80, 255);
    doc.text("Nombre y Firma", 95, 260);

    doc.text("Nombre y Firma", 155, 260);



    doc.text("CALLE IGNACIO RAMIREZ No. 10 LOC. EL SAÚZ ALTO, PEDRO ESCOBEDO, QRO. ", 30, 280);
    doc.text("TEL. (442)  456 3037 Y (448) 101 3730 E-MAIL: fumigacionessantafe.jc@outlook.com", 30, 285);



    //guardar pdf con nombre en especifico 
    doc.save(`registro_servicio_90_05_2024.pdf`);

  }
  
  

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Registro Servicios</h1>
            </div>
            <div className="col-sm-6">
            <div className="App">
              <button onClick={generarPDF}>Generar PDF</button>
              {/* <PDFFile /> */}
            </div>
            
              {/* <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">General Form</li>
              </ol> */}
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>

      <section className="content">
        <div class="container-fluid">
          {/* general form elements disabled */}
          <div className="card card-default">
            {/* <div className="card-header">
          <h3 className="card-title">Registro de Servicios</h3>
        </div> */}
            {/* /.card-header */}
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Fecha</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={"Viernes, 23 de febrero de 2024"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Folio</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={"1"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Codigo</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={"REG-OP-01"}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    {/* text input */}
                    <div className="form-group">
                      <label>Cliente</label>
                      <select
                        className="form-control select2"
                        style={{ width: "100%" }}
                      >
                        <option selected="selected">INDUSTRIA ENVASADORA DE QUERETARO S.A DE C.V. (IEQSA)</option>
                        <option>Alaska</option>
                        <option>California</option>
                        <option>Delaware</option>
                        <option>Tennessee</option>
                        <option>Texas</option>
                        <option>Washington</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Direccion</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={"Carretera No 57, km 194"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* /.card-body */}
          </div>
        </div>

        <div className="container-fluid">
          {/* /.card */}
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Striped Full Width Table</h3> */}
            </div>
            {/* /.card-header */}
            <div className="card-body p-0">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Areas</th>
                    <th>Actividad</th>
                    <th>Plaga</th>
                    <th>Metodo</th>
                    <th>Cantidad</th>
                    <th style={{ width: 40 }}>Sanamiento</th>
                    <th style={{ width: 40 }}>Mantenimiento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Interior Bodega 3</td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>Nula</option>
                          <option>Muy Baja</option>
                          <option>Baja</option>
                          <option>Baja</option>
                          <option>Alta</option>
                          <option>Muy Baja</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>AL</option>
                          <option>AB</option>
                          <option>M</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>IR</option>
                          <option>IV</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>Lt</option>
                          <option>Pza</option>
                          <option>Otros</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>SI</option>
                          <option>NO</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>SI</option>
                          <option>NO</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Interior Bodega 4</td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>Nula</option>
                          <option>Muy Baja</option>
                          <option>Baja</option>
                          <option>Baja</option>
                          <option>Alta</option>
                          <option>Muy Baja</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>AL</option>
                          <option>AB</option>
                          <option>M</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>IR</option>
                          <option>IV</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>Lt</option>
                          <option>Pza</option>
                          <option>Otros</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>SI</option>
                          <option>NO</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>SI</option>
                          <option>NO</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Interior Bodega 5</td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>Nula</option>
                          <option>Muy Baja</option>
                          <option>Baja</option>
                          <option>Baja</option>
                          <option>Alta</option>
                          <option>Muy Baja</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>AL</option>
                          <option>AB</option>
                          <option>M</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>IR</option>
                          <option>IV</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>Lt</option>
                          <option>Pza</option>
                          <option>Otros</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>SI</option>
                          <option>NO</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <select class="custom-select">
                          <option>SI</option>
                          <option>NO</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}

          {/* /.card */}
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Striped Full Width Table</h3> */}
            </div>
            {/* /.card-header */}
            <div className="card-body p-0">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Ingrediente Activo</th>
                    <th>Nombre Activo</th>
                    <th>Registro CoFepris</th>
                    <th>Dosis</th>
                    <th>Mezcla Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>BROMADIOLONA</td>
                    <td>
                      C-REAL B PARAFINADO
                    </td>
                    <td>
                      RSCO-URB-RODE-511-339-033-0.005
                    </td>
                    <td>
                    <input type="text" value={1}></input>
                    </td>
                    <td>
                      <input type="text" value={1}></input>
                    </td>
                  </tr>
                  <tr>
                    <td>Thiametoxam</td>
                    <td>
                      Optigard Flex
                    </td>
                    <td>
                      RSCO-URB-INAC-102U-315-064-21
                    </td>
                    <td>
                      <input type="text" value={1}></input>
                    </td>
                    <td>
                      <input type="text" value={1}></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}

          {/* /.------- card  PLAGUICIDAS UTILIZADOS --------*/}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">OBSERVACIONES DEL TÉCNICO :</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body p-0">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Hallazgo</th>
                    <th>Recomendaciones</th>
                    <th>Responsables de las actividades</th>
                    <th>Fecha Compromiso</th>
                    <th>Fecha Cierre</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="text"></input>
                    </td>
                    <td>
                      <input type="text"></input>
                    </td>
                    <td>
                    <input type="text"></input>

                    </td>
                    <td>
                    <input type="text"></input>

                    </td>
                    <td>
                    <input type="text"></input>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>

        <div className="container-fluid">
       
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Observaciones Cliente</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body p-0">
                {/* textarea */}
                <div class="col-sm-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Enter ..."
                    defaultValue={""}
                  />
                </div>
                </div>
              </div>
            </div>
          
        </div>
      </section>


    </div>
  );
}
